body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Apply scrollbar globally */
* {
  scrollbar-width: auto; /* Firefox: 'thin' se 'auto' kar diya for better visibility */
  scrollbar-color: #007bff #f1f1f1; /* Thumb | Track */
}

/* Webkit Browsers (Chrome, Edge, Safari) */
::-webkit-scrollbar {
  width: 14px; /* Mota scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Light background */
  border-radius: 10px;
}


