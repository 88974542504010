/*Color variables*/
/*gray variables*/
/*white variables*/
/*black variables*/
/*Dark Theme*/
/*Dark Theme*/
#ui_notifIt {
  position: fixed;
  top: 10px;
  right: 10px;
  cursor: pointer;
  overflow: hidden;
  -o-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  -wekbit-border-radius: 5px;
  border-radius: 5px;
  z-index: 20000;
}
#ui_notifIt:hover {
  opacity: 1 !important;
}
#ui_notifIt p {
  text-align: center;
  font-size: 14px;
  padding: 0;
  margin: 0;
  padding: 10px;
  font-weight: 400;
  text-transform: capitalize;
}
#ui_notifIt p i {
  font-size: 20px;
}

#notifIt_close {
  position: absolute;
  color: #ffffff;
  top: 0;
  padding: 0px 5px;
  right: 0;
}
#notifIt_close:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

/* Color setup */
/* You are free to change all of this */
#ui_notifIt.success {
  background-color: #19b159;
  color: white;
}
#ui_notifIt.error {
  background-color: #fd6074;
  color: white;
}
#ui_notifIt.warning {
  background-color: #ff9b21;
  color: white;
}
#ui_notifIt.info {
  background-color: #01b8ff;
  color: white;
}
#ui_notifIt.default {
  background-color: #7987a1;
  color: #ffffff;
}
#ui_notifIt.primary {
  background-color: var(--primary-bg-color);
  color: #ffffff;
}
#ui_notifIt.dark {
  background-color: #3b4863;
  color: #ffffff;
}

/* notifit confirm */
.notifit_confirm_bg,
.notifit_prompt_bg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
}

.notifit_confirm,
.notifit_prompt {
  position: fixed;
  top: 0;
  left: 0;
  padding: 30px;
  background-color: #ffffff;
  border: 0;
  border-radius: 1px;
  box-shadow: 0px 2px 10px rgb(162, 172, 202);
  z-index: 9999;
}

.notifit_confirm_accept,
.notifit_confirm_cancel,
.notifit_prompt_accept,
.notifit_prompt_cancel {
  display: inline-block;
  font-weight: 400;
  color: #160248;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 4px;
  margin-right: 5px;
  outline: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.notifit_prompt_accept:hover,
.notifit_prompt_cancel:hover {
  background-color: #666;
  outline: none;
}

.notifit_confirm_accept:focus,
.notifit_confirm_cancel:focus {
  outline: none;
}

.notifit_confirm_accept {
  background-color: #19b159;
  color: #ffffff;
}

.notifit_confirm_cancel {
  background-color: #fd6074;
  color: #ffffff;
}

.notifit_confirm_message {
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
}

.notifit_prompt_message {
  color: #444;
  margin-top: 0;
  text-align: center;
}

.notifit_prompt_input {
  text-align: center;
  font-size: 14px;
  width: 100%;
  padding: 10px;
  outline: none;
  border: 1px solid #aaa;
  color: #444;
  border-radius: 5px;
}

.notifit_prompt {
  text-align: center;
}

#ui_notifIt.info {
  margin: 0 auto;
  right: 10px !important;
  left: 10px !important;
}/*# sourceMappingURL=notifIt.css.map */